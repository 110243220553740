const $ = require("jquery")

// require("bootstrap/js/src/alert")
// require("bootstrap/js/src/button")
require("bootstrap/js/src/carousel")
require("bootstrap/js/src/collapse")
// require("bootstrap/js/src/dropdown")
// require("bootstrap/js/src/index")
require("bootstrap/js/src/modal")
// require("bootstrap/js/src/popover")
// require("bootstrap/js/src/scrollspy")
// require("bootstrap/js/src/tab")
// require("bootstrap/js/src/toast")
// require("bootstrap/js/src/tooltip")
// require("bootstrap/js/src/util")

import ImageGallery from './image-gallery'


// Page scrolling

$('[data-scrollTo]').on('click', function (e) {
    e.preventDefault()
    $([document.documentElement, document.body]).animate({
            scrollTop: $($(this).attr('href')).offset().top,
        }, 500, 'linear'
    )
})

// ImageGallery
ImageGallery('.image-gallery');
